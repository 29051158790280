<!-- =========================================================================================
    File Name: KnowledgeBase.vue
    Description: Knowledge Base Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <div id="knowledge-base-page">
        <!-- <div class="dx-field">
       <vscript script='<script id="sw-script" async src="https://js.storywidget.com/story.js" data-swid="6b456d5e-d587-446b-a71d-08772309e343"></script>'></vscript> 
        </div> -->
        <!-- JUMBOTRON -->
        <div class="knowledge-base-jumbotron">
            <div class="knowledge-base-jumbotron-content lg:p-32 md:p-24 sm:p-16 py-8 rounded-lg mb-base">
                <h1 class="mb-1 text-white">Ceddan Bilişim Teknolojileri</h1>
                <h2 class="mb-1 text-white">En<strong> İyisiyle </strong> Çalıştığınızdan Emin Olmalısınız!</h2>
                <p class="text-white">Marketten Restorana,Zincir Mağazalardan Kobi İşletmelerine,İnşaat'tan Gıda'ya, Otomotiv'den Mobilya'ya, Demir-Çelik'ten Enerji'ye hemen hemen tüm üretim sektörlerinde yeralan şirketlerden, İthalat ve İhracat alanında sektörünün önünde yeralan şirketlerden, Perakende ve Toptan Alım-Satım alanında faaliyet gösteren tüm ticari kuruluşlara, Lojistik, Eğitim,Dershane,Kolej,Sağlık ve Turizm'den Müşavirlik Hizmetlerine kadar tüm hizmet sektöründe her ölçekteki Özel ve Kamu kuruluşlarına yönelik ürünleri ile kullanıcı odaklı özel çözümler sunuyoruz.</p>
                <vs-input placeholder="Arama Konusu veya Anahtar Kelime" v-model="knowledgeBaseSearchQuery" icon-pack="feather" icon="icon-search" size="large" class="w-full no-icon-border mt-6" />
            </div>
        </div>

        <!-- KNOWLEDGE BASE CARDS  -->
        <div class="vx-row">
                <div class="vx-col w-full md:w-1/3 sm:w-1/2 mb-base" v-for="item in filteredKB" :key="item.id" >
                    <vx-card class="text-center cursor-pointer">
                        <img :src="require(`@/assets/images/pages/${item.graphic}`)" loading="lazy" :alt="item.title" width="180" class="mx-auto mb-4" @click="$router.push({ name: 'barkod-sistemi'})">
                        <h4 class="mb-2">{{ item.title.toUpperCase() }}</h4>
                        <small>{{ item.description }}</small>
                    </vx-card>
                </div>
        </div>  

        <!-- FAQ COL -->
        <div class="vx-row">
            <div class="vx-col w-full mt-12 md:mt-0" id="faq-page" itemscope itemtype="https://schema.org/FAQPage">
                <vs-collapse accordion type="margin" class="p-0">
                    <vs-collapse-item itemscope itemprop="mainEntity" itemtype="https://schema.org/Question" v-for="(que,index) in faqs" class="faq-bg rounded-lg" :class="{'mt-0': !index}" :key="que.id">
                        <div slot="header" itemprop="name"><h5>{{ que.question }}</h5></div>
                        <div itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                        <p v-html="que.ans" itemprop="text">{{ que.ans }}</p>
                        </div>
                    </vs-collapse-item>
                </vs-collapse>
                
            </div>
        </div>
        <!-- youtube -->
        <!-- <youtube :video-id="videoId"  :player-vars="playerVars" @playing="playing"></youtube> -->
        
    </div>
</template>

<script>

import vscript from 'vue-script-component'



export default {
    components: {
        vscript
    },
    data() {
        return {
            videoId: 'laoApL3qQEk',
            playerVars: {autoplay: 1},
            knowledgeBaseSearchQuery: '',
            kb: [
                { id: 1, title: 'BARKOD SİSTEMİ', description: 'Şirketinizin A-Z ye Otomasyon Alt Yapısı Kurulumu ve Yönetimi.', graphic: 'graphic-1.png' ,url: '/barkod-sistemi'},
                { id: 2, title: 'WEB TASARIM', description: 'Kurumsal Kimlik Web Sayfası Tasarım ve İçerik Yönetimi', graphic: 'graphic-2.png' ,url: '/web-tasarim'},
                { id: 3, title: 'TİCARİ YAZILIM', description: 'İşletmelerin ihtiyaç duydukları tüm iş süreçlerini yöneten yazılım KARPİN', graphic: 'graphic-3.png' ,url: '/yazilim'},
                { id: 4, title: 'E-TİCARET', description: 'İşinizi İnternete taşıyoruz.Satışlarınız ve Stoklarınızı Güncel Kalmasını sağlıyoruz.', graphic: 'graphic-4.png' ,url: '/e-ticaret'},
                { id: 5, title: 'MOBİL UYGULAMA', description: 'Webten, Cepten, Tabletten İşinizi yönetmenizi sağlıyoruz.', graphic: 'graphic-5.png' ,url: '/mobil-uygulama'},
                { id: 6, title: 'TEKNİK DESTEK', description: 'Bilgisiyar,Network,Printer,Uzak bağlantı,VPN,Cisco,Altyapınızı sağlıyoruz.', graphic: 'graphic-6.png' ,url: '/teknik-destek'},
            ],
            faqs: [
              
                {
                    id: 1,
                    categoryId: 4,
                    question: 'Barkod Sistemi Nedir?',
                    ans: 'Barkod Sistemi, Market,Mağaza,Depo veya fabrikanızın Barkod cihazları ile entegre çalışarak işlerinizin hızlı ve pratik yürütülmesi birlikteliği bütününü oluşturur. Hemen almak için <a href=/shop>buraya tıklayarak ürünlere bakın</a>.'
                },
                {
                    id: 2,
                    categoryId: 4,
                    question: 'Barkod Cihazları Nelerdir?',
                    ans: 'Barkod Cihazları; Barkod Okuyucu, Barkod Yazıcı, El Terminalleri, Barkodlu Sistem Terazileri ve Yazarkasa ve Pos Cihazları ile Yeni Nesil Ödeme Kaydedici  Kasalardır'
                },
                {
                    id: 3,
                    categoryId: 4,
                    question: 'Hangi Yazarkasayı Kullanmalıyım?',
                    ans: 'İşletmenizin Ölçeğine ve Yapılacak işlem sayısına göre yazarkasanızı seçmelisiniz. Ufak Ölçekli İşletmeler için Giriş seviyesi Yazarkasa (Hugin,Profilo,Beko vs), Orta Ölçekli İşletmeler ise enPos YN-500 lie enPos YN-101  ÖKC Yazarkasa idealdir. Büyük ölçekli İşletmeler için ise enPos YN-100,enPos YN-200, Toshiba 4610 Serisi cihazlar idealdir.  Hemen denemek için <a href=/shop>buraya tıklayarak satın alabilirsiniz.</a>.'
                },
                {
                    id: 4,
                    categoryId: 4,
                    question: 'Hangi Teraziyi Almalıyım?',
                    ans: 'Terazi barkod sistemi tartım ve etiketleme işlemlerinizi hızlandırmak için yeni nesil barkod etiket baskı ölçeği çözümü olarak ifadelendiriliyor. Aslında dikkat etmeniz gereken önemli püf noktaları var. Zira terazi barkod sistemi öyle kısa süreli kullanacağınız bir cihaz değil çünkü. Hemen her gün bu cihaza ihtiyacınız var. Çünkü hayatın akışı içerisinde fiyatların çok da sabit olmadığı bir zaman dilimini hep beraber yaşıyoruz.'
                },
                {
                    id: 5,
                    categoryId: 4,
                    question: 'Karpin Yazılım Nerelerde Kullanılıyor?',
                    ans: 'Karpin, Marketten Restorana,Zincir Mağazalardan Kobi İşletmelerine,İnşaat tan Gıdaya, Otomotiv den Mobilya ya, Demir-Çelik ten Enerji ye hemen hemen tüm üretim sektörlerinde yeralan şirketlerden, İthalat ve İhracat alanında sektörünün önünde yeralan şirketlerden, Perakende ve Toptan Alım-Satım alanında faaliyet gösteren tüm ticari kuruluşlara, Lojistik, Eğitim,Dershane,Kolej,Sağlık ve Turizm den Müşavirlik Hizmetlerine kadar tüm hizmet sektöründe her ölçekteki Özel ve Kamu kuruluşlarına yönelik ürünleri ile kullanıcı odaklı özel çözümler sunar :)'
                },
            ],
        }
    },
    computed: {
        filteredKB() { 
            return this.kb.filter((item) => item.title.toLowerCase().includes(this.knowledgeBaseSearchQuery.toLowerCase()) || item.description.toLowerCase().includes(this.knowledgeBaseSearchQuery.toLowerCase()));
        },
        player() {
      return this.$refs.youtube.player
    }
    },
    metaInfo() {
    return {
      title: 'Barkod Sistemi Ankara Ceddan Bilisim',
       meta: [
         {property: 'og:type', content: 'website'},
         {property: 'og:title', content: 'Barkod Sistemi Ankara Ceddan Bilisim' },
         {property: 'og:description', content: 'Barkod Sistemi, Market Sistemi, Restoran Sistemi, Barkod Yazici, Barkod Okuyucu, Yazarkasa, Dokunmatik Pos, Termal Fis Yazici, Terazi ve Baskul, Etiket ve Rulo Satisi'},
         {property: 'og:url', content: 'https://www.ceddan.com'+this.$route.path},
         {property: 'og:image', content: 'https://www.ceddan.com/webpage-300x200.png'},
         {name: 'x-canonical-url',content:'https://www.ceddan.com'+this.$route.path},
         {rel:'canonical',url:'https://www.ceddan.com'+this.$route.path}
       ] 
    }

  },
    jsonld() {
      return [
        {
		"@context": "http://schema.org",
		"@id": "https://www.ceddan.com/#webpage",
		"@type": "WebPage",
		"url": "https://www.ceddan.com/",
		"name": "Ceddan"
	},
        { "@context" : "https://schema.org",
        "@type" : "Organization",
        "name": "ceddan.com",
        "url" : "https://www.ceddan.com",
        "logo": "https://www.ceddan.com/logo.png",
        "sameAs": [
            "https://www.facebook.com/ceddan",
            "https://twitter.com/ceddanbilisim",
            "https://www.youtube.com/user/ceddanbilisim",
            "https://www.instagram.com/ceddanbilisim"
        ],
        "contactPoint" : [
          { "@type" : "ContactPoint",
            "telephone" : "+90-312-527-48-88",
            "contactType" : "customer service",
            "contactOption" : "TollFree",
            "areaServed" : "TR"
          } , {
            "@type" : "ContactPoint",
            "telephone" : "+90-536-499-17-17",
            "contactType" : "Sales"
          } , 
           {
            "@type" : "ContactPoint",
            "telephone" : "+90-312-527-48-88",
            "contactType" : "technical support",
            "contactOption" : "TollFree",
            "areaServed" : "TR",
            "availableLanguage" : ["English","Turkish","Arabic"]
          } , {
            "@type" : "ContactPoint",
            "telephone" : "+90-312-527-48-88",
            "contactType" : "bill payment",
            "contactOption" : "TollFree",
            "areaServed" : ["EN","TR"]
          } ] 
      },
      {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [{
          "@type": "ListItem",
          "position": 1,
          "item": {
            "@id": "https://www.ceddan.com/shop",
            "name": "Mağaza"
            
          }
        },{
          "@type": "ListItem",
          "position": 2,
          "item": {
            "@id": "https://www.ceddan.com/services",
            "name": "Hizmetler"
          }
        },{
          "@type": "ListItem",
          "position": 3,
          "item": {
            "@id": "https://www.ceddan.com/references",
            "name": "Referanslarımız"
          }
        },{
          "@type": "ListItem",
          "position": 4,
          "item": {
            "@id": "https://www.ceddan.com/support",
            "name": "Destek"
          }
        },{
          "@type": "ListItem",
          "position": 5,
          "item": {
            "@id": "https://www.ceddan.com/about",
            "name": "Hakkımızda"
          }
        },
          {
            "@type": "ListItem",
            "position": 6,
            "item": {
              "@id": "https://www.ceddan.com/contact",
              "name": "İletişim"
            }},
          {
              "@type": "ListItem",
              "position": 7,
              "item": {
                "@id": "https://www.ceddan.com/faq",
                "name": "Barkod Sistemi Hakkında"
          }
        }]
      },{
      "@context":"http://schema.org",
      "@type":"ItemList",
      "itemListElement":[
        {
          "@type": "SiteNavigationElement",
          "position": 1,
          "name": "Ana Sayfa",
          "description": "Barkod Sistemi Ankara Ceddan Bilişim | Barkod Otomasyon | Market Sistemi | Restoran Sistemi | E-Ticaret Entegrasyonu | işletmelerin ihtiyaç duydukları tüm iş süreçlerini entegre bir şekilde sağlayan ticari yazılım Karpin",
          "url":"https://www.ceddan.com"
        },
        {
          "@type": "SiteNavigationElement",
          "position": 2,
          "name": "Hakkımızda",
          "description": "Bizim Hakkımızda",
          "url":"https://www.ceddan.com/about"
        },
        {
          "@type": "SiteNavigationElement",
          "position": 3,
          "name": "Hizmetlerimiz",
          "description": "Yaptığımız İşler Nelerdir",
          "url":"https://www.ceddan.com/services"
        },
        {
          "@type": "SiteNavigationElement",
          "position": 4,
          "name": "Sık Sorulan Sorular",
          "description": "Barkod Sistemi Hakkında Soru Cevaplar",
          "url":"https://www.ceddan.com/faq"
        },
        {
          "@type": "SiteNavigationElement",
          "position":5,
          "name": "Referanslar",
          "description": "Bizi Tercih Eden Bazı Müşterilerimiz",
          "url":"https://www.ceddan.com/references"
        },
        {
          "@type": "SiteNavigationElement",
          "position":6,
          "name": "Destek",
          "description": "İndir, İzle , Öğren",
          "url":"https://www.ceddan.com/support"
        },
        {
          "@type": "SiteNavigationElement",
          "position":7,
          "name": "Mağaza",
          "description": "Barkod Mağazası",
          "url":"https://www.ceddan.com/shop"
        },
        {
          "@type": "SiteNavigationElement",
          "position": 8,
          "name": "İletişim",
          "description": "İhtiyaç duyduğunuzda Bizimle İletişime Geçin",
          "url":"https://www.ceddan.com/contact"
        }
      ]
    }
      ]
    },
    methods: {
      playVideo() {
      this.player.playVideo()
    },
    created(){
      //this.playVideo()
    },
    mounted(){
      this.playVideo();
      

    },
    playing() {
      console.log('\o/ we are watching!!!')
    }
    }
}
</script>

<style lang="scss">
.knowledge-base-jumbotron-content {
    background-image: url('../../assets/images/pages/knowledge-base-cover.jpg');
    background-size: cover;
}
</style>